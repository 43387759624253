<template>
  <div class="w-full text-sm">
    <asterix-comments
      :comment-list="commentList"
      :tags="tags"
      :loading="isLoading"
      @create-or-update="onCreateOrUpdateComment"
      @delete="deleteComment"
    >
      <template #title>Comments</template>
    </asterix-comments>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';

import AsterixComments from '@/components/organisms/shared/asterixComments/AsterixComments';
import CONFIG from './config';
import { createDealComment, updateDealComment, deleteDealComment } from '@/services/modules/dashboardSSP/platformDeals';
import PlatformDeal from '@/entities/dashboardSSP/PlatformDeals';

export default {
  name: 'DealComments',
  components: {
    AsterixComments,
  },
  props: {
    platformDeal: { type: PlatformDeal, default: null },
  },
  data: () => ({
    isLoading: false,
    tags: CONFIG.tags,
  }),
  computed: {
    commentList() {
      return this.platformDeal?.comments || [];
    },
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async onCreateOrUpdateComment(comment) {
      const updateOrCreateText = comment?.id ? 'created' : 'updated';
      try {
        this.isLoading = true;

        if (comment.id) {
          await updateDealComment(this.platformDeal.id, comment);
        } else {
          await createDealComment(this.platformDeal.id, comment);
        }
        const toast = Toast.success(`Comment ${updateOrCreateText}`, `Comment was ${updateOrCreateText} successfully`);
        this.createToast(toast);

        this.$emit('update', this.platformDeal);
      } catch (e) {
        this.createToast(Toast.error(`Comment not ${updateOrCreateText}`, e.message));
      } finally {
        this.loading = false;
      }
    },
    async deleteComment(comment) {
      try {
        this.isLoading = true;
        await deleteDealComment(this.platformDeal.id, comment);
        const toast = Toast.success('Comment deleted', 'Comment was deleted successfully');
        this.createToast(toast);

        this.$emit('update', this.platformDeal);
      } catch (e) {
        this.createToast(Toast.error('Comment not deleted', e.message));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
